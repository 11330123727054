import * as React from 'react';

import MenuLink from './MenuLink';

const MenuLinks = (): JSX.Element => {
    return (
        <ul className="menu-links">
            <MenuLink path="/" linkText="Home" />
            <MenuLink path="/about-us/" linkText="About Us" />
            <MenuLink path="/news/" linkText="News" parentActive={true} />
            <MenuLink path="/careers/" linkText="Careers" parentActive={true} />
            <MenuLink path="/contact-us/" linkText="Contact Us" />
        </ul>
    );
};

export default MenuLinks;
