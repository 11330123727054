import * as React from 'react';
import { Helmet } from 'react-helmet';

import '../styles/layout.less';
import CookieConsent from './CookieConsent';

import Footer from './Footer';
import Header from './Header';

interface LayoutParams {
    children: JSX.Element;
    classname?: string;
}

const Layout = ({ children, classname }: LayoutParams): JSX.Element => {
    const mainClass = classname ? `${classname} main` : `main`;

    return (
        <div className="layout">
            <Helmet titleTemplate="%s | CC Media Network" />
            <Header />
            <main className={mainClass}>{children}</main>
            <Footer />
            <CookieConsent />
        </div>
    );
};

export default Layout;
