import * as React from 'react';
import { Link } from 'gatsby';

interface CTATypes {
    classname: 'primary' | 'secondary' | 'submit' | 'action' | 'details';
    text: string;
    path?: string;
    onClick?: any;
    type?: 'button' | 'reset' | 'submit';
    disabled?: boolean;
}

const CTA = ({ classname, text, path, onClick, type = 'button', disabled = false }: CTATypes): JSX.Element => {
    return (
        <>
            {path ? (
                <Link to={path} className={`cta ${classname}`}>
                    {text}
                </Link>
            ) : (
                <button className={`cta ${classname}`} onClick={onClick} type={type} disabled={disabled}>
                    {text}
                </button>
            )}
        </>
    );
};

export default CTA;
