import * as React from 'react';
import axios, { AxiosResponse } from 'axios';

import { validateForm } from '../utils/validation';
import { EMAIL_REGEX, IS_INVALID, IS_REQUIRED } from '../constants';

import CTA from './CTA';
import HalfC from '../images/halfc.inline.svg';

interface CareerFormParams {
    email: string;
    errors: {
        email: boolean | null;
    };
}
type FieldNames = 'email';

const Subscribe = (): JSX.Element => {
    const [disabled, setDisabled]: [boolean, Function] = React.useState<boolean>(true);
    const [formSent, setFormSent]: [boolean, Function] = React.useState<boolean>(false);
    const [state, setState]: [CareerFormParams, Function] = React.useState<CareerFormParams>({
        email: '',
        errors: {
            email: null,
        },
    });

    const handleChange = (e: { target: HTMLInputElement | HTMLTextAreaElement }) => {
        const value = e.target.value;
        const name = e.target.name as FieldNames;
        const errors = state.errors;

        switch (name) {
            case 'email':
                errors[name] = !EMAIL_REGEX.test(value);
                break;
            default:
                break;
        }

        setDisabled(!validateForm(errors));
        setState((prevState: CareerFormParams) => {
            return {
                ...prevState,
                [name]: value.trim(),
            };
        });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('email', state.email);

        const apiBaseUrl = process.env.GATSBY_API_URL as string;
        axios({
            method: 'post',
            url: 'newsletter.php',
            baseURL: apiBaseUrl,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: formData,
        })
            .then(function (response: AxiosResponse) {
                setFormSent(true);
            })
            .catch(function (error: any) {
                throw new Error(error);
            });
    };

    const message: JSX.Element = (
        <div className="subscribe-wrapper is-subscribed">
            <div className="subscribe">
                <div className="titles">
                    <h3 className="title">Thank you!</h3>
                    <p className="subtitle">You have successfully subscribed to our newsletter!</p>
                </div>
                <HalfC className="half-c" />
            </div>
        </div>
    );

    const form: JSX.Element = (
        <div className="subscribe-wrapper">
            <div className="subscribe">
                <div className="titles">
                    <p className="title">Subscribe to newsletter</p>
                    <p className="subtitle">Subscribe to our newsletter to get our news updates first.</p>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    <fieldset>
                        <label htmlFor="email" className={`label ${IS_REQUIRED} ${state.errors.email ? IS_INVALID : ''}`} aria-label="Email">
                            <input className="input" type="email" placeholder="E-mail" name="email" id="email" value={state.email} onChange={handleChange} />
                        </label>
                        <CTA classname="primary" text="Subscribe to newsletter" type="submit" disabled={disabled} />
                    </fieldset>
                </form>
                <HalfC className="half-c" />
            </div>
        </div>
    );

    return formSent ? message : form;
};

export default Subscribe;
