import * as React from 'react';
import { Link } from 'gatsby';

interface MenuLinkTypes {
    path: string;
    linkText: string;
    parentActive?: boolean;
}

const MenuLink = ({ path, linkText, parentActive = false }: MenuLinkTypes): JSX.Element => {
    const linkClass = 'menu-link';

    return (
        <li className={linkClass}>
            <Link to={path} activeClassName="active" partiallyActive={parentActive}>
                {linkText}
            </Link>
        </li>
    );
};

export default MenuLink;
