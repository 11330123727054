import * as React from 'react';

import Favicon from '../images/favicon.inline.svg';
import { Link } from 'gatsby';
import MenuLink from './MenuLink';
import MenuLinks from './MenuLinks';
import Subscribe from './Subscribe';

const Footer = (): JSX.Element => {
    const currentYear: number = new Date().getFullYear();

    return (
        <div className="footer">
            <Subscribe />
            <footer className="footer-main">
                <MenuLinks />
                <div className="divider">
                    <div className="line" />
                    <div className="sphere-grey" />
                    <div className="line" />
                </div>
                <div className="menu-links policies">
                    <ul className="sub-menu-links">
                        <MenuLink path="/privacy-policy/" linkText="Privacy Policy" />
                        <MenuLink path="/cookie-policy/" linkText="Cookie Policy" />
                    </ul>
                </div>
                <Link to="/" title="CC Media Network">
                    <Favicon className="favicon" />
                </Link>
                <div className="copyright">CC Media Network Limited ©, All rights reserved, {currentYear}</div>
            </footer>
        </div>
    );
};

export default Footer;
