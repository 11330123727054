import * as React from 'react';
import { Link } from 'gatsby';

import Close from '../images/close.inline.svg';
import Hamburger from '../images/hamburger.inline.svg';
import Logo from '../images/logo.inline.svg';
import MenuLinks from './MenuLinks';

const Header = (): JSX.Element => {
    const [menuOpen, setMenuOpen]: [boolean, Function] = React.useState<boolean>(false);
    const menuOpenClosed: string = menuOpen ? 'open' : 'closed';

    const doSetMenuOpen = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
            <div className="menu">
                <Link to="/" title="CC Media Network">
                    <Logo className="logo" />
                </Link>
                <button className={`toggle-btn ${menuOpenClosed}`} onClick={doSetMenuOpen}>
                    {menuOpen ? <Close className="icon" /> : <Hamburger className="icon" />}
                </button>
                <MenuLinks />
            </div>
            <div className={`menu-expanded ${menuOpenClosed}`}>
                <MenuLinks />
            </div>
        </header>
    );
};

export default Header;
