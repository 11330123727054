export const setCookie = (name: string, value: any, days?: number) => {
    let expires: string;

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    } else {
        expires = '';
    }

    document.cookie = `${name}=${value}${expires}; path=/; secure; SameSite=strict`;
};

export const getCookie = (name: string) => {
    const nameEQ = name + '=';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
};

export const removeCookie = (name: string) => {
    setCookie(name, '', -1);
};
