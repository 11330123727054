export const validateForm = (errors: any): boolean => {
    let valid = true;
    Object.values(errors).some((value: boolean) => (value === true || value === null) && (valid = false));
    return valid;
};

export const isValidUrl = (url: string): boolean | URL => {
    try {
        return new URL(url);
    } catch (e) {
        return false;
    }
};

export const MESSAGE_FORM_INVALID = 'Please fill in required fields';
export const MESSAGE_URL_INVALID = 'Provided URL is not valid';
export const MESSAGE_MAX_FILE_SIZE = 'Maximum allowed file size: 10MB';
export const MESSAGE_ALLOWED_FILE_TYPES = 'Allowed file types: .doc, .docx, .odt, .pdf';
