import * as React from 'react';
import { Link } from 'gatsby';

import CTA from './CTA';
import { setCookie, getCookie } from '../utils/cookies';
import { IS_VISIBLE } from '../constants';

import '../styles/cookieConsent.less';

const CookieConsent = (): JSX.Element => {
    const [cookiesAccepted, setCookiesAccepted]: [boolean, Function] = React.useState<boolean>(true);

    React.useEffect(() => setCookiesAccepted(!!getCookie('ccmedia-cookies-accepted')));

    const handleCookieAccept = () => {
        setCookie('ccmedia-cookies-accepted', true, 365);
        setCookiesAccepted(true);
    };

    return (
        <div className={`cookie-consent ${cookiesAccepted ? '' : IS_VISIBLE}`}>
            <p className="cookie-consent__description">
                <b>This website uses cookies</b>
                <br />
                Cookies tell us which parts of our websites people have visited, help us measure the effectiveness of ads and web searches, and give us insights into user behaviour so we can improve our communications and products. <Link to="/cookie-policy/">Read more</Link>.
            </p>
            <p className="cookie-consent__btn">
                <CTA classname="action" text="Ok" onClick={handleCookieAccept} />
            </p>
        </div>
    );
};

export default CookieConsent;
